import React from "react";
import "../App.css"
import MenuIcon from "../static/menu.png"
import PhoneIcon from "../static/phone.png"
import lunchMenu from "../static/lunchmenu.pdf";
export const OrderNowComponent = () => {
    const styles = {
        container: {
            width: "70%",
            minHeight: 450,
            display: "flex",
            flexDirection: "row",
            flexWrap: 'wrap',
            justifyContent: "space-around"
        },
        card: {
            justifyContent: 'center',
            textAlign: 'center',
            flex: 1,
            margin: 20,
            marginBottom: 50,
            minWidth: 180,
            borderRadius: 20,
            textDecoration: "none",
            color: "black"
        }
    }

    return (
        <div className="center" style={{marginTop: 20}}>
            <div style={styles.container}>
                <a className="card" style={styles.card} href="tel:01968672410">
                    <h2>Call Us</h2>
                    <img src={PhoneIcon} alt="delivery" width="150"  height="150"/>
                    <p>Give us a ring at 0196 867 2410!</p>
                </a>
                <a className="card" style={styles.card} href={lunchMenu}  target="_blank">
                    <h2>Lunch Menu</h2>
                    <img src={MenuIcon} alt="delivery" width="150"  height="150"/>
                    <p>View our Lunch Menu, served Fridays and Saturdays!</p>
                </a>
                {/*<div className="card" style={styles.card}>*/}
                {/*    <h2>Dinner Menu</h2>*/}
                {/*    <img src={MenuIcon} alt="delivery" width="150"  height="150"/>*/}
                {/*    <p>View Our Dinner Menu</p>*/}
                {/*</div>*/}
            </div>

        </div>
    )
}
