import React, {useState} from "react";
import {useLocation} from 'react-router-dom'
import '../App.css'
import Logo from '../static/logo.svg'
import HamburgerMenu from 'react-hamburger-menu'

export const HeaderComponent = () => {
    const location = useLocation().pathname;
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open)
    }
    return (
        <div className="Header">
            <div className="header-items">
                <div className="nav-logo">
                    <img src={Logo} height={100} style={{maxHeight: 50, objectFit: "contain"}}/>
                </div>
                <div className="Nav-Items">
                    <a className="nav-item-button" href="https://koshirestaurant.co.uk/order-now" target="_blank">Order Now</a>
                    <a className="nav-item-button" href="https://koshirestaurant.co.uk/book-table" target="_blank">Book A table</a>
                    <a className="nav-item-button" href="tel:01968672410">Call Us</a>
                </div>
                {/*<div className='mobile-menu'>*/}
                {/*    <HamburgerMenu*/}
                {/*        isOpen={open}*/}
                {/*        menuClicked={handleClick}*/}
                {/*        width={25}*/}
                {/*        height={20}*/}
                {/*        strokeWidth={1.5}*/}
                {/*        rotate={1}*/}
                {/*        color='white'*/}
                {/*        borderRadius={0}*/}
                {/*        animationDuration={0.5}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            {/*{open &&*/}
            {/*<div className='mobile-nav'>*/}
            {/*    <a className="nav-item-button" href="https://koshirestaurant.co.uk/order-now">Order Now</a>*/}
            {/*    <a className="nav-item-button" href="https://koshirestaurant.co.uk/book-table">Book A table</a>*/}
            {/*    <a className="nav-item-button" href="tel:01968672410">Call Us</a>*/}
            {/*</div>*/}

            {/*}*/}
        </div>
    )
}
