import './App.css';
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom';
import {RestaurantPage} from "./Pages/RestaurantPage";
import React from "react";
import {FooterComponent} from "./Components/FooterComponent";
import MessengerCustomerChat from 'react-messenger-customer-chat'
import {GalleryPage} from "./Pages/GalleryPage";
import {HeaderComponent} from "./Components/HeaderComponent";

function App() {
  return (
      <div>
          <MessengerCustomerChat appId='222245919894902' pageId='107484988371952'/>
          <Router>
              <div className="App">
                  {/*<HeaderComponent/>*/}
                  <Switch>
                      {/*<Route path="/venue">*/}
                      {/*  <VenuePage/>*/}
                      {/*</Route>*/}
                      <Route path="/gallery">
                        <GalleryPage/>
                      </Route>
                      <Route path="/">
                          <RestaurantPage/>
                      </Route>
                  </Switch>
              </div>
              <FooterComponent />
          </Router>
      </div>
  );
}

export default App;
