import React from 'react'
import '../App.css'
import Facebook from '../static/facebook.svg'
import Instagram from '../static/instagram.svg'

export const FooterComponent = () => {

    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },

        info: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',

        },
        columnTab: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        socials: {
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            display: 'flex',
            minWidth: 300,
            flexDirection: 'row',
            fontWeight: 800,
            justifyContent: 'center'
        },
    }
    return (
        <div className="footer">
            <div style={styles.container}>
                <div id='socials' style={styles.columnTab}>
                    <h3>Find Us On</h3>
                    <div style={styles.socials}>
                        <img style={{cursor: "pointer" }} src={Facebook} alt="Facebook" width={85} height={56} onClick={() => window.open('https://www.facebook.com/koshiPenicuik')}/>
                        <img style={{cursor: "pointer" }} src={Instagram} alt="Instagram" width={56} height={56} onClick={() => window.open('https://www.instagram.com/koshiPenicuik/')}/>
                        <p style={{marginLeft: 20, cursor: "pointer" }} onClick={() => window.open('https://www.facebook.com/koshiPenicuik')}>@KoshiPenicuik</p>
                    </div>
                </div>
                <div id="about">
                    <div style={styles.info}>
                        <h2>Contact Us</h2>
                        <span>1 Imrie Place, Penicuik, EH26 8HY</span>
                        <span>contact@koshi.co.uk</span>
                        {/*<span>0131 111 1111</span>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}
