import React from "react"

export const MapComponent = () => {
    const url = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2240.988344883272!2d-3.221707383909174!3d55.828161593644566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4887c04ab70a59f1%3A0xdce8694c06a5e7f6!2s1%20Imrie%20Pl%2C%20Penicuik!5e0!3m2!1sen!2suk!4v1611663420364!5m2!1sen!2suk"
    return (
        <div>
            <iframe
                src={url}
                width="100%" height="500" frameBorder="0" style={{border: 0}} allowFullScreen="" aria-hidden="false"
                tabIndex="0">
            </iframe>
        </div>
    )
}
