import React from "react";
import "../App.css"
import FoodImage from "../static/food.jpg";
import FacebookIcon from "../static/facebook.png";


export const OurStoryComponent = () => {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "row",
            flexWrap: 'wrap',
            justifyContent: "space-around"
        },
        cardImage: {
            justifyContent: 'center',
            textAlign: 'center',
            flex: 1,
            maxHeight: 450
        },
        cardText: {
            justifyContent: 'center',
            textAlign: 'center',
            flex: 2,
            margin: 20,
            marginBottom: 50,
            Width: 180
        }
    }

    return (

        <div className='center'>

            <div className="our-story" style={styles.container}>
                <div style={styles.cardText}>
                    {/*<img src={DineInIcon} alt="delivery" width="150"/>*/}
                    <p style={{fontSize: 25}}>Koshi symbolises the unity of 3 different nations (Nepal,India and Tibet). Here at Koshi, a Nepalese and Indian Restaurant, our vision is to bring you the traditional culture, flavours, and ingredients from each of these nations to your doorstep in a modern style. We are committed to serving only the very best and authentic recipes, using locally sourced fresh Scottish produce, and rare and unique herbs and spices from Nepal and India to ensure each dish is cooked to perfection and a high standard.</p>
                    <p style={{fontSize: 25}}>Our ultimate goal is to ensure our customers are able to taste and experience traditional and unique cuisines in a modern style, in an excellent ambience and receive the most impeccable service. Whether dining alone, or with friends and family. We hope to create the most satisfying and unforgettable dining experience.</p>
                </div>
            </div>

        </div>

    )
}
