import React from "react";
import {WelcomeComponent} from "../RestaurantComponents/WelcomeComponent";
import {OurStoryComponent} from "../RestaurantComponents/OurStoryComponent";
import "../App.css"
import {OurServicesComponent} from "../RestaurantComponents/OurServicesComponent";
import {MapComponent} from "../Components/MapComponent";
import {OrderNowComponent} from "../RestaurantComponents/OrderNowComponent";

export const RestaurantPage = () => {
    return (
        <div style={{display: "flex", flexDirection: "column", flex: 1, justifyContent: "center"}}>
            <WelcomeComponent isVenue={false}/>
            <div>
                {/*<h1 className="big-text">More Ways to Order</h1>*/}
                <OrderNowComponent/>
                <h1 className="big-text">Our Services</h1>
                <OurServicesComponent/>
            </div>
            <h1 className="big-text">Our Story</h1>
            {/*<h3  style={{marginBottom: 100, textAlign: "center"}}>Please Place your bookings via Facebook or Clicking on the 'Chat' button below!</h3>*/}
            <OurStoryComponent/>
            <MapComponent/>
        </div>
    )
}
