import React, {useEffect, useState} from "react";
import "../App.css"
import Down from "../static/down.svg";
import christmasMenu from "../static/christmasMenu.pdf";
import {HeaderComponent} from "../Components/HeaderComponent";

export const WelcomeComponent = ({isVenue}) => {

    const [text, setText] = useState({})

    useEffect(() => {
        if(isVenue){
            setText({title: 'Koshi Events', subTitle: 'Celebrate your special occasion with us'})
        }
        else {
            setText({title: 'Koshi', subTitle: 'Culture Through Cuisine'})
        }
    }, [])

    const styles = {
        title: {
            margin: 0,
            padding: 0,
            fontSize: "3em",
            color: "white",
            letterSpacing: "8px"
        },
        subTitle: {
            margin: 0,
            marginTop: 20,
            padding: 0,
            fontSize: "1.2em",
            letterSpacing: "5px",
            color: "white"
        },

    }
    return (
        <div className={`page-height ${isVenue ? 'venue-welcome': 'welcome-screen'}`}>
           <div>
               <HeaderComponent />
           </div>
            <div className={`base-main`}>
                <div className="fade-in" style={{textAlign: "center"}}>
                    <div style={styles.title}>Welcome to <span style={{color: '#D9B765', fontWeight: 'bold'}}>{text.title}</span></div>
                    <h1 style={styles.subTitle}>{text.subTitle}</h1>
                    {/*<h1 style={styles.subTitle}>Opening Soon</h1>*/}
                    <div style={{marginTop: 50}}>
                        <a className='welcomeButton' href="https://koshirestaurant.co.uk/order-now" target="_blank">Order Now</a>
                        <a className='welcomeButton' href="https://koshirestaurant.co.uk/book-table" target="_blank">Book A Table</a>
                        {/*<a className='welcomeButton' href={christmasMenu} target="_blank">Christmas Menu</a>*/}
                    </div>

                </div>
            </div>
            <div className='down-arrow bounce'>
                <img src={Down}  style={{maxHeight: 50, objectFit: "contain"}}/>
            </div>
        </div>
    )
}
