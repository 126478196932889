import React from "react";
import "../App.css"
import DeliveryIcon from "../static/delivery.png"
import DineInIcon from "../static/dinein.png"
import TakeAwayIcon from "../static/takeaway.png"
import CateringIcon from "../static/catering.png"

export const OurServicesComponent = () => {
    const styles = {
        container: {
            width: "70%",
            minHeight: 450,
            display: "flex",
            flexDirection: "row",
            flexWrap: 'wrap',
            justifyContent: "space-around"
        },
        card: {
            justifyContent: 'center',
            textAlign: 'center',
            flex: 1,
            margin: 20,
            marginBottom: 50,
            minWidth: 180,
            borderRadius: 20
        }
    }

    return (
        <div className="center" style={{marginTop: 20}}>
            <div style={styles.container}>
                <div style={styles.card}>
                    <h2>Deliveries</h2>

                    <img src={DeliveryIcon} alt="delivery" width="150"/>
                    <p>Piping hot meals right to your doorstep.</p>

                </div>
                <div style={styles.card}>
                    <h2>Dine In</h2>
                    <img src={DineInIcon} alt="delivery" width="150"/>
                    <p>Join us at Imrie place for a fine dining experience.</p>
                </div>
                <div style={styles.card}>
                    <h2>Takeaway</h2>
                    <img src={TakeAwayIcon} alt="delivery" width="150"/>
                    <p>Order a takeaway to enjoy your way.</p>
                </div>
                <div style={styles.card}>
                    <h2>Outside Catering</h2>
                    <img src={CateringIcon} alt="delivery" width="150"/>
                    <p>Whatever the occasion may be, we can fully cater to your needs.</p>
                </div>
            </div>

        </div>
    )
}
